<template>
  <WeContainer v-if="ready" card="">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="fas fa-pencil-alt"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="contract.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Title -->
      <WeInput
        label="Başlık"
        v-model="form.title"
        v-bind:required="true"
        v-bind:error="$v.form.title.$error"
      />
      <!-- ./Title -->
      <!-- Slug -->
      <WeSlugCheck
        v-bind:value="form.title"
        v-bind:id="$route.params.id"
        v-bind:slug="form.slug"
        v-bind:search="true"
        v-on:slugify="changeSlug"
        url-fragment="sozlesme"
        type="contract"
      />
      <!-- ./Slug -->
      <!-- Content -->
      <WeEditor
        title="İçerik"
        name="contract"
        v-model="form.content"
        v-bind:accessToken="session.accessToken"
        v-bind:mention-feed="mentionFeed"
      />
      <!-- ./Content -->
      <WeSwitch v-model="form.is_emailable" label="E-Posta Gönder" />
      <WeSwitch v-model="form.is_approvable" label="Onay Gerektiren" />
      <WeSwitch v-model="form.is_contact" label="İletişim Formu" />
      <WeSwitch v-model="form.is_stock_notify" label="Gelince Haber Ver" />

      <WeSwitch
        v-model="form.confirm_on_register"
        label="Üye Kaydında Onay Gerektiren"
      />

      <WeMetaData v-model="form.meta" />
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      submit: false,
      ready: true,
      mentionFeed: [
        { id: "@SellerTitle", name: "Satıcı Ünvanı" },
        { id: "@SellerAddress", name: "Satıcı Adresi" },
        { id: "@SellerPhone", name: "Satıcı Telefonu" },
        { id: "@SellerFax", name: "Satıcı Faks" },
        { id: "@SellerEmail", name: "Satıcı E-Posta" },
        { id: "@SellerKep", name: "Satıcı KEP Adresi" },
        { id: "@SellerMersis", name: "Satıcı Mersis No" },
        { id: "@SellerBankAccount", name: "Satıcı Banka Hesap/IBAN No" },
        {
          id: "@ReturnCargoCompany",
          name: "İade Durumunda Kullanılacak Kargo Şirketi",
        },
        {
          id: "@ReturnAddress",
          name: "İade Durumunda Ürünün Gönderileceği Adres",
        },
        { id: "@BuyerNameSurname", name: "Alıcı Ad Soyad" },
        { id: "@BuyerShipmentAddress", name: "Alıcı Teslimat Adresi" },
        { id: "@BuyerShipmentPhone", name: "Alıcı Telefonu" },
        { id: "@BuyerShipmentEmail", name: "Alıcı E-Posta" },
        { id: "@BuyerInvoiceTitle", name: "Alıcı Fatura Ad, Soyad / Ünvan" },
        { id: "@BuyerInvoiceAddress", name: "Alıcı Fatura Adresi" },
        { id: "@BuyerInvoicePhone", name: "Alıcı Fatura Telefonu" },
        { id: "@BuyerInvoiceEmail", name: "Alıcı Fatura E-Posta" },
        { id: "@ProductList", name: "Ürün Listesi" },
        { id: "@OrderDate", name: "Sipariş Tarihi" },
        { id: "@PaymentType", name: "Ödeme Türü" },
      ],
      form: {
        title: null,
        content: null,
        is_approvable: false,
        is_emailable: false,
        slug: null,
        is_contact: false,
        is_stock_notify: false,
        confirm_on_register: false,
        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  methods: {
    ...mapActions("contract", ["create", "update", "delete", "getById"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;
      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData);
        this.updateContract();
      } else {
        this.addContract();
      }
    },
    changeSlug(data) {
      this.form.slug = data;
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Sözleşmeler listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addContract() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateContract() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getContractById() {
      this.ready = false;
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    redirectToList() {
      this.$router.push("/contracts");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
  },
  validations: {
    form: {
      title: {
        required,
      },
      content: {
        required,
      },
    },
  },
  computed: {
    ...mapState(["contract", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "SÖZLEŞME DÜZENLE" : "SÖZLEŞME EKLE";
    },
    checkPermission() {
      return permission.check("contract", "u");
    },
  },
  mounted() {
    this.getContractById();
  },
  watch: {
    "contract.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getContractById();
        }
      },
    },
  },
};
</script>
